import React  from 'react';

import QFalse from '../../img/q-false.png';
import QTrue from '../../img/q-true.png';

const ResultsTableClassic = (props) => {
    const renderQualified = (qualified) => {
        if ("boolean" === typeof qualified) {
            return qualified ? <img src={QTrue} alt="Qualified"/> : <img src={QFalse} alt="Non-qualified"/>
        } else {
            return qualified === "ANO"
                ? <img src={QTrue} alt="Qualified"/>
                : (qualified === "REDANCE"
                    ? <span style={{fontSize: '16px', color: '#000000'}}>R</span>
                    : <img src={QFalse} alt="Non-qualified"/>
                )
        }
    }

    return <table className={"results-table results-table--classic"}>
        <thead>
            <tr>
                <th className={"results-table__column results-table__column--pos"} />
                <th className={"results-table__column results-table__column--stn"} />
                <th className={"results-table__column results-table__column--school"}>Kolektiv</th>
                <th className={"results-table__column results-table__column--competitor"}>Choreografie</th>
                <th className={"results-table__column results-table__column--qualified"}>Q</th>
            </tr>
        </thead>
        <tbody>
        { props.results.map((result, key) => {
            return <tr key={key}>
                <td className={"results-table__column results-table__column--pos"}>{result.pos}</td>
                <td className={"results-table__column results-table__column--stn"}>{result.stn}</td>
                <td className={"results-table__column results-table__column--school"}>{result.school}</td>
                <td className={"results-table__column results-table__column--competitor"}>{result.competitor}</td>
                <td className={"results-table__column results-table__column--qualified"}>{renderQualified(result.qualified)}</td>
            </tr>
        }) }
        </tbody>
    </table>
};

export default ResultsTableClassic;